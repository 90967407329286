import React from "react";
import FoundationPage from "../components/FoundationPage";
import Layout from "../components/Layout";

export default function FAQPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Frequently Asked Questions"
        // description=""
      >
        <React.Fragment>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>What is a design system?</h2>
            <p className="description mt-space-200">
              A design system is a set of design foundations, reusable
              components, and repeatable patterns that designers and developers
              use to create products. A good design system creates consistency
              and efficiency in building software products.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>Who is building this system?</h2>
            <p className="description mt-space-200">
              GDS is built by Brad Siefert, Brendan Nofs, and Matt Huntsberry.
              Roie Reuveni did excellent work creating icons for GDS. Edit Peled
              keeps the system accessible for everyone. James Acklin and Jim
              Jerse got the ball rolling. Joanna Vasiliakis is the boss.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>Is GDS ready to use?</h2>
            <p className="description mt-space-200">
              Yes! Absolutely. There are parts that are still in development,
              but GDS is the future and the present. Designers and developers
              alike should start adopting GDS in their products. The sooner
              everyone starts using it, the sooner we’ll enjoy the benefits that
              a single design system brings.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>
              What if I don’t see a component I need for one of my products?
            </h2>
            <p className="description mt-space-200">
              Let’s talk about it! We may have a different vision for the UI
              you’re trying to build or maybe we haven’t even thought about
              building your component yet. We want GDS to work well for everyone
              at Nielsen. Please grab some time with us if you have specific UI
              needs we haven’t addressed.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>
              Which products are going to use GDS?
            </h2>
            <p className="description mt-space-200">
              Anything that’s being built new should use GDS. Some retrofitting
              of applications will happen. Those priorities will be determined
              by product leadership.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>
              What technology platforms can use GDS?
            </h2>
            <p className="description mt-space-200">
              All platforms. We’re building GDS to be a cohesive set of UI
              components and design patterns that will work on mobile and web
              applications.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>
              My web application uses [name of once trendy javascript
              framework]. Can I use GDS?
            </h2>
            <p className="description mt-space-200">
              The answer is almost certainly yes. GDS is being built as a set of
              React components in MAF and as Lit Web Components that work with
              any frontend framework. Nielsen applications using React, Angular,
              Vue, Svelte, etc. can all use GDS.
            </p>
          </div>
          <div className="section">
            <h2 style={{ maxWidth: "768px" }}>What about Gracenote?</h2>
            <p className="description mt-space-200">
              Gracenote is included in GDS! One of the key parts of building GDS
              with Web Components is to enable Gracenote to adopt the GDS
              components more quickly. We’re excited to align Gracenote and
              Nielsen’s UIs to look and work together more seamlessly.
            </p>
          </div>
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
